.Activity .Activity-details {
    padding: 40px;
}

.Activity .row {
    margin-right: 0;
}

.Activity .Activity-details .dot {
    display: inline-block;
    background-color: #2EA493;
    width: 5px;
    height: 5px;
    margin: 0 10px 3px 10px;
}

.Activity .Activity-details h1 {
    margin: 40px 0 20px 0;
    font-size: 26px;
    font-family: 'Montserrat';
    font-weight: 600;
}

.Activity .Activity-details img {
    margin-bottom: 30px;
}

.Activity .Activity-details .Activity-content img {
    max-width: 100%;
}

.Activity .Activity-back {
    color: #2EA493;
    cursor: pointer;
    margin-top: 40px;
}

.Activity .Activity-back:hover {
    text-decoration: underline;
}