.Footer {
  height: 250px;
  background-color: #000000;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Footer .Footer-dots-left {
  background: url("../_images/dots-footer.svg") #000000 left center no-repeat no-repeat;
  width: 100%;
  height: 100%;
}

.Footer .Footer-content {
  min-width: 450px;
  color: #aaaaaa;
  text-align: center;
}

.Footer .Footer-title {
  font-family: 'Montserrat', sans-serif;
  font-weight: 900;
  font-size: 20px;
  text-align: center;
  margin-bottom: 20px;
}

.Footer .Footer-text {
  font-size: 12px;
  line-height: 25px;
}

.Footer .Footer-text .Footer-logout {
  color: #2EA493;
  cursor: pointer;
}

.Footer .Footer-text .Footer-logout:hover {
  text-decoration: underline;
}

.Footer .Footer-dots-right {
  background: url("../_images/dots-horizontal-footer.svg") #000000 right center no-repeat no-repeat;
  width: 100%;
  height: 100%;
  margin-right: 50px;
  margin-left: -50px;
}

.Copyright {
  background-color: #1C1C1C;
  height: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Copyright .Copyright-content {
  color: #aaaaaa;
  font-size: 12px;
  margin-bottom: 0;
}

.Copyright a {
  color: #aaaaaa;
  text-decoration: underline;
}

.Copyright a:hover {
  color: #dddddd;
}

