.Publications .Publications-header {
  background-color: #2EA493;
  color: #ffffff;
  height: 200px;
}

.Publications .Header-container {
  height: 200px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Publications .Header-container h1 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
}

.Publications .Header-container a {
  color: #ffffff;
}

.Publications .Publications-section {
  min-height: calc(100vh - 620px);
}

.Publications .Publications-section .Section-group {
  margin: 40px 0;
}

.Publications .Publications-section .Section-group h2 {
  font-weight: bold;
  font-size: 24px;
}

.Publications .Publications-section .Section-group .Section-group-content {
  margin: 40px 0;
}