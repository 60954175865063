@import url(https://fonts.googleapis.com/css?family=Montserrat:500,600,900&display=swap);
@import url(https://fonts.googleapis.com/css?family=Roboto:400,700&display=swap);
@import url(https://db.onlinewebfonts.com/c/100dfc4e81c630ca07b6327a25d08f4a?family=Linux+Libertine);
body {
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
  font-weight: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #000000;
}

body *::selection {
  background-color: #2EA493;
  color: #ffffff;
}

body *::-moz-selection {
  background-color: #2EA493;
  color: #ffffff;
}

a {
  color: #2EA493;
}

a:hover,
a:active,
a:focus {
  color: #2EA493;
  text-decoration: underline;
  outline: none;
}

.Btn-primary {
  display: inline-block;
  padding: 4px 32px;
  margin: 0;
  font-size: 14px;
  font-weight: 600;
  line-height: 2.3;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  border: 1px solid transparent;
  height: 40px;
  color: #ffffff;
  background-color: #2EA493;
  text-shadow: none;
}

.Btn-primary:hover,
.Btn-primary:active,
.Btn-primary:focus {
  color: #ffffff;
  text-decoration: none;
  background-color: #2F9A89;
}

.Btn-secondary {
  display: inline-block;
  padding: 4px 32px;
  margin: 0;
  font-size: 14px;
  font-weight: bold;
  line-height: 2.3;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  border: 1px solid #333333;
  height: 40px;
  color: #333333;
  text-shadow: none;
  background-color: transparent;
}

.Btn-secondary:hover,
.Btn-secondary:active,
.Btn-secondary:focus {
  color: #ffffff;
  text-decoration: none;
  background-color: #333333;
}

.Switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 22px;
}

.Switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.Switch .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .4s;
}

.Switch .slider:before {
  position: absolute;
  content: "";
  height: 14px;
  width: 14px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: .4s;
}

.Switch input:checked + .slider {
  background-color: #2EA493;
}

.Switch input:focus + .slider {
  box-shadow: 0 0 1px #2EA493;
}

.Switch input:checked + .slider:before {
  transform: translateX(18px);
}

/* Rounded sliders */
.Switch .slider {
  border-radius: 34px;
}

.Switch .slider:before {
  border-radius: 50%;
}
.Title {
  margin-top: 60px;
  margin-bottom: 40px;
  text-align: center;
}

.Title .Title-main {
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
}

.Title .Title-subtitle {
  color: #999999;
}

.Title .Title-line {
  width: 70px;
  height: 3px;
  background-color: #2EA493;
  margin: 0 auto;
}
.About .About-header {
  background-color: #2EA493;
  color: #ffffff;
  height: 200px;
}

.About .Header-container {
  height: 200px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.About .Header-container h1 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
}

.About .Header-container a {
  color: #ffffff;
}

.About .About-project {
  text-align: center;
  width: 50%;
  margin: 0 auto;
}

.About .About-researchers {
  background-color: #f5f5f5;
  min-height: 450px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 60px;
}

.About .About-researchers .Researchers-images {
  display: flex;
  justify-content: space-around;
}

.About .About-researchers .Researchers-images div {
  display: flex;
  flex-direction: column;
  text-align: center;
}

.About .About-researchers .Researchers-images img {
  height: 300px;
}

@media (max-width: 991px) {
  .About .About-researchers .Researchers-images {
    margin-top: 30px;
  }

  .About .About-researchers .Researchers-text {
    margin-top: 30px;
    margin-bottom: 30px;
  }
}

.About .About-researchers .Researchers-text h3 {
  font-weight: bold;
  font-size: 20px;
  margin-bottom: 10px;
}

.About .About-researchers .Researchers-text p {
  font-size: 16px;
}

.About .About-purpose {
  min-height: 450px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.About .About-purpose .Purpose-text h3 {
  font-weight: bold;
  font-size: 20px;
  margin-bottom: 10px;
}

.About .About-purpose .Purpose-locations {
  height: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.About .About-purpose .Location-box {
  width: 120px;
  height: 170px;
  box-shadow: 0 0 15px #dadada;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.About .About-purpose .Location-box img {
  height: 40px;
  margin-top: 10px;
  margin-bottom: 15px;
}

.About .About-purpose .Location-box .Location-city {
  font-weight: bold;
  font-size: 20px;
}

.About .About-numbers {
  background-color: #2EA493;
  min-height: 200px;
  color: #ffffff;
}

.About .About-numbers .Numbers-text {
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
}

.About .About-numbers .Numbers-text .Numbers-number {
  font-weight: bold;
  font-size: 50px;
}

.About .About-supporters .Supporters-logos {
  margin-top: 80px;
  margin-bottom: 100px;
  text-align: center;
}

.About .About-collaborators {
  background-color: #f5f5f5;
  padding: 10px 50px 50px 50px;
}

.About .About-collaborators h3 {
  font-weight: bold;
  font-size: 20px;
}

.About .About-collaborators .Collaborators-links h3 {
  margin-top: 40px;
  font-size: 24px;
}

.About .About-collaborators .Collaborators-links div {
  margin-top: 20px;
  font-weight: bold;
}

.About .About-activities {
  text-align: center;
}

.About .About-activities h3 {
  margin-top: 80px;
  margin-bottom: 50px;
  font-weight: bold;
}

.About .About-activities .Activities-section {
  border-top: 3px solid #2EA493;
  box-shadow: 0 0 15px #dadada;
  width: 100%;
  text-align: left;
  padding: 30px;
  margin-bottom: 20px;
  height: calc(100% - 30px);
  cursor: pointer;
}

.About .About-activities .Activities-section .Activities-title {
  font-weight: bold;
  font-size: 20px;
  margin: 20px 0;
}

.About .About-activities .Activities-section:hover .Activities-title {
  text-decoration: underline;
}

.About .About-activities .Activities-section img {
  width: 100%;
}

.About .About-activities .Activities-section .Activity-no-image {
  width: 100%;
  height: 200px;
  background-color: #eeeeee;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.About .About-activities .Activities-section .Activity-no-image h1 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 900;
  font-size: 24px;
  color: #aaaaaa;
}

.About .About-activities .Activities-section .Activity-no-image:hover h1 {
  text-decoration: none;
  color: #999999;
}

.About .About-activities .Btn-primary {
  margin-bottom: 50px;
}
.Activities .row {
    margin-right: 0;
}

.Activities-popular {
    color: #ffffff;
    background-color: #2EA493;
    padding: 40px;
    height: 100%;
}

.Activities-popular h2 {
    font-size: 18px;
    text-align: center;
    margin: 30px 0;
}

.Activities-popular .Activities-recent-item {
    margin-top: 10px;
}

.Activities-popular .Activities-recent-item:hover {
    cursor: pointer;
}

.Activities-popular .Activities-recent-item img {
    width: 100%;
    height: 200px;
    object-fit: cover;
}

.Activities-popular .Activities-recent-item .Activity-no-image {
    width: 100%;
    height: 200px;
    background-color: #eeeeee;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}

.Activities-popular .Activities-recent-item .Activity-no-image h1 {
    font-family: 'Montserrat', sans-serif;
    font-weight: 900;
    font-size: 24px;
    color: #aaaaaa;
}

.Activities-popular .Activities-recent-item .Activity-no-image:hover h1 {
    text-decoration: none;
    color: #999999;
}

.Activities-popular .Activities-recent-item:hover h3 {
    text-decoration: underline;
}

.Activities-popular .Activities-recent-item h3 {
    font-size: 16px;
    margin: 15px 0;
}

.Activities .Activities-list {
    margin: 60px;
}

.Activities .Activities-list h2 {
    font-family: 'Montserrat';
    font-weight: 600;
}

.Activities .Activities-list .Activities-navigation {
    float: right;
    margin-top: -35px;
    font-size: 14px;
}

.Activities .Activities-list .Activities-list-item {
    border-bottom: 2px solid #E6E6E6;
    padding: 30px 0;
}

.Activities .Activities-list .Activities-list-item .Activity-no-image {
    width: 100%;
    height: 250px;
    background-color: #eeeeee;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}

.Activities .Activities-list .Activities-list-item .Activity-no-image h1 {
    font-family: 'Montserrat', sans-serif;
    font-weight: 900;
    color: #aaaaaa;
}

.Activities .Activities-list .Activities-list-item .Activity-no-image:hover h1 {
    text-decoration: none;
    color: #999999;
}

.Activities .Activities-list .Activities-list-item img {
    cursor: pointer;
    width: 100%;
    height: 250px;
    object-fit: cover;
}

.Activities .Activities-list .Activities-list-item .hr {
    width: 50px;
    height: 3px;
    background-color: #2EA493;
    margin-bottom: 15px;
}

.Activities .Activities-list .Activities-list-item h1 {
    font-size: 24px;
    margin-top: 10px;
    margin-bottom: 15px;
}

.Activities .Activities-list .Activities-list-item h1:hover {
    text-decoration: underline;
    cursor: pointer;
}

.Activities .Activities-list .Activities-list-item .Activities-list-details {
    color: #999999;
    margin-bottom: 15px;
}

.Activities .Activities-list .Activities-list-item .Activities-list-details .dot {
    display: inline-block;
    background-color: #2EA493;
    width: 5px;
    height: 5px;
    margin: 0 20px 3px 20px;
}

.Activities .Activities-list .Activities-list-item .Activities-list-details .Activities-list-type {
    background-color: #2EA493;
    color: #ffffff;
    padding: 5px 10px;
    border-radius: 20px;
}

.Activities .Activities-list .Activities-list-item .Activities-list-details .Btn-primary {
    margin-top: 20px;
}

.Activities .Activities-list .Activities-list-item .Activities-list-buttons > a + a,
.Activities .Activities-list .Activities-list-item .Activities-list-buttons > a + button {
    margin-left: 10px;
}

.Activities .Activities-pagination  {
    margin-top: 40px;
}

.Activities .Activities-pagination span {
    border: 1px solid #E6E6E6;
    color: #2EA493;
    padding: 10px 15px;
    cursor: pointer;
    font-weight: 600;
}

.Activities .Activities-pagination span.active {
    color: #ffffff;
    background-color: #2EA493;
    border: 1px solid #2EA493;
}

.Activities .Activities-pagination span.disabled {
    color: #999999;
}

.PageNotFound {
    min-height: calc(100vh - 380px);
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.PageNotFound h1 {
    font-family: 'Montserrat';
    font-size: 100px;
}

.Activity .Activity-details {
    padding: 40px;
}

.Activity .row {
    margin-right: 0;
}

.Activity .Activity-details .dot {
    display: inline-block;
    background-color: #2EA493;
    width: 5px;
    height: 5px;
    margin: 0 10px 3px 10px;
}

.Activity .Activity-details h1 {
    margin: 40px 0 20px 0;
    font-size: 26px;
    font-family: 'Montserrat';
    font-weight: 600;
}

.Activity .Activity-details img {
    margin-bottom: 30px;
}

.Activity .Activity-details .Activity-content img {
    max-width: 100%;
}

.Activity .Activity-back {
    color: #2EA493;
    cursor: pointer;
    margin-top: 40px;
}

.Activity .Activity-back:hover {
    text-decoration: underline;
}

.ActivityNew .hidden {
    display: none !important;
}

.ActivityNew .ActivityNew-buttons {
    display: block;
    text-align: right;
    margin-top: 120px;
    margin-bottom: 40px;
}

.ActivityNew .ActivityNew-buttons button + button {
    margin-left: 10px;
}

.ActivityNew .Text-input {
    display: block;
    margin: 20px 0;
    min-width: 500px;
    width: 100%;
    padding: 10px;
    border: 1px solid #CACACA;
}

.ActivityNew .Dropdown-input .dropdown-toggle {
    background-color: #333333;
    border: 1px solid #333333;
    border-radius: 25px;
    box-shadow: none;
    outline: 0;
}

.ActivityNew .Dropdown-input .dropdown-toggle:hover,
.ActivityNew .Dropdown-input .dropdown-toggle:focus,
.ActivityNew .Dropdown-input .dropdown-toggle:active,
.ActivityNew .Dropdown-input .dropdown-toggle.active {
    outline: 0;
    background-color: #333333;
    border: 1px solid #333333;
    box-shadow: none !important;
}

.ActivityNew .Dropdown-input .dropdown-item:active {
    background-color: #eeeeee;
    color: #16181b;
}

.ActivityNew .ActivityNew-translations {
    padding: 15px 15px 5px 15px;
    background-color: #F5F5F5;
    margin: 15px 0;
}

.ActivityNew .Language-switch {
    display: inline-block;
    margin-left: 15px;
}

.ActivityNew .Language-switch .Switch {
    margin-right: 5px;
}

.ActivityNew .ActivityNew-Language-tabs {
    margin-top: 40px;
}

.ActivityNew .Label {
    border-radius: 25px;
    padding: 2px 10px;
    margin-right: 15px;
}

.ActivityNew .Label.active {
    background-color: #2EA493;
    border: 1px solid #2EA493;
    color: #ffffff;
}

.ActivityNew .Label:not(.disabled) {
    /*cursor: pointer;*/
}

.ActivityNew .Label.disabled {
    color: #999999;
}

.ActivityNew .Label.active.disabled {
    background-color: #F5F5F5;
    border-color: #F5F5F5;
}

.ActivityNew .ActivityNew-editor {
    margin: 50px 0;
}

.ActivityNew .Activity-wrapper a {
    color: #000000;
}

.ActivityNew .Activity-wrapper .rdw-editor-toolbar {
    border: 1px solid #CACACA;
    margin-bottom: 0;
    border-bottom: none;
}

.ActivityNew .Activity-editor {
    height: 500px;
    padding: 0 20px;
    border: 1px solid #CACACA;
}

.Footer {
  height: 250px;
  background-color: #000000;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Footer .Footer-dots-left {
  background: url(/static/media/dots-footer.d066f96c.svg) #000000 left center no-repeat no-repeat;
  width: 100%;
  height: 100%;
}

.Footer .Footer-content {
  min-width: 450px;
  color: #aaaaaa;
  text-align: center;
}

.Footer .Footer-title {
  font-family: 'Montserrat', sans-serif;
  font-weight: 900;
  font-size: 20px;
  text-align: center;
  margin-bottom: 20px;
}

.Footer .Footer-text {
  font-size: 12px;
  line-height: 25px;
}

.Footer .Footer-text .Footer-logout {
  color: #2EA493;
  cursor: pointer;
}

.Footer .Footer-text .Footer-logout:hover {
  text-decoration: underline;
}

.Footer .Footer-dots-right {
  background: url(/static/media/dots-horizontal-footer.94502937.svg) #000000 right center no-repeat no-repeat;
  width: 100%;
  height: 100%;
  margin-right: 50px;
  margin-left: -50px;
}

.Copyright {
  background-color: #1C1C1C;
  height: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Copyright .Copyright-content {
  color: #aaaaaa;
  font-size: 12px;
  margin-bottom: 0;
}

.Copyright a {
  color: #aaaaaa;
  text-decoration: underline;
}

.Copyright a:hover {
  color: #dddddd;
}


@media (min-width: 768px) {
  .Header .navbar {
    height: 75px;
  }
}

.Header .navbar-brand {
  font-family: 'Montserrat', sans-serif;
  font-weight: 900;
}

.Header .navbar-brand:hover {
  color: #444;
}

.Header .navbar-nav {
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  font-size: 12px;
}

.Header .navbar-nav .nav-link {
  color: black;
}

.Header .navbar-nav .nav-link:hover,
.Header .navbar-nav .nav-link:active,
.Header .navbar-nav .nav-link:focus,
.Header .navbar-nav .nav-link.active {
  color: #2EA493;
}

.Header .navbar-toggler:focus {
  outline: none;
}

.Header .Language-dropdown .dropdown-menu {
  min-width: 7rem;
}

.Header .Language-dropdown .dropdown-item {
  font-size: 14px;
}

.Header .Language-dropdown .dropdown-item.active,
.Header .Language-dropdown .dropdown-item:active {
  background-color: #2EA493;
}

@media (max-width: 767px) {
  .Header .navbar-collapse .nav-link {
    font-size: 16px;
  }
}
.Home .Home-hero {
  position: relative;
}

.Home .Home-hero .Home-background {
  background: url(/static/media/bg-header.c4bba91c.png) #000000 left center no-repeat no-repeat;
  background-size: cover;
  width: 100%;
  height: calc(100vh - 75px);
}

/* TODO - Home-background responsiveness */

.Home .Home-hero .Home-text {
  width: 100%;
  text-align: center;
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, 0);
  color: #ffffff;
}

.Home .Home-hero .Home-text h2 {
  font-size: 20px;
}

.Home .Home-hero .Home-text h1 em {
  color: #2EA493;
  font-family: 'Linux Libertine', serif;
  font-size: 50px;
}

.Home .Home-hero .Home-explore {
  cursor: pointer;
}

/* TODO - add a change on hover */
.Home .Home-hero .Home-explore .Home-arrows {
  position: absolute;
  bottom: 65px;
  left: 50%;
  transform: translate(-50%, 0);
}

.Home .Home-hero .Home-explore .Home-explore-btn {
  color: #ffffff;
  font-size: 14px;
  position: absolute;
  bottom: 15px;
  left: 50%;
  transform: translate(-50%, 0);
}

.Home .Home-about,
.Home .Home-goals,
.Home .Home-custom,
.Home .Home-activities,
.Home .Home-testimonials,
.Home .Home-publications,
.Home .Home-team {
  margin-top: 120px;
}


.Home .Home-about .Collaboration-img {
  max-width: 100%;
  max-height: 380px;
}

.Home .Home-about .About-emphasized {
  font-size: 20px;
}

.Home .Home-goals {
  margin-bottom: 40px;
}

.Home .Home-goals .Goals-section {
  box-shadow: 0 0 15px #dadada;
  width: 100%;
  text-align: center;
  padding: 30px;
  margin-bottom: 20px;
  height: calc(100% - 30px);
}

.Home .Home-goals .Goals-section img {
  height: 100px;
}

.Home .Home-goals .Goals-section h3 {
  font-size: 18px;
  font-weight: bold;
  margin-top: 20px;
  margin-bottom: 10px;
}

.Home .Home-goals .Goals-section p {
  font-size: 14px;
  color: #999999;
}

.Home .Home-custom .Woman-img {
  background: url(/static/media/woman.27fee9bd.png) bottom center no-repeat no-repeat;
  background-size: cover;
  height: 450px;
}

.Home .Home-custom .Custom-content {
  background-color: #000000;
  color: #ffffff;
  height: 450px;
  padding: 60px 80px;
  position: relative;
}

.Home .Home-custom .Custom-content h3 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  font-size: 2rem;
}

.Home .Home-custom .Custom-content .Custom-line {
  width: 70px;
  height: 2px;
  background-color: #2EA493;
  margin-top: 20px;
  margin-bottom: 30px;
}

.Home .Home-custom .Custom-content p {
  max-width: 460px;
  margin-bottom: 30px;
}

.Home .Home-custom .Custom-content .Dots-img {
  position: absolute;
  bottom: 0;
  right: 0;
}

.Home .Home-activities {
  text-align: center;
}

.Home .Home-activities .Activities-text {
  width: 50%;
  margin: 0 auto 30px auto;
}

.Home .Home-activities .Activities-section {
  border-top: 3px solid #2EA493;
  box-shadow: 0 0 15px #dadada;
  width: 100%;
  text-align: left;
  padding: 30px;
  margin-bottom: 20px;
  height: calc(100% - 30px);
  cursor: pointer;
}

.Home .Home-activities .Activities-section .Activities-title {
  font-weight: bold;
  font-size: 20px;
  margin: 20px 0;
}

.Home .Home-activities .Activities-section:hover .Activities-title {
  text-decoration: underline;
}

.Home .Home-activities .Activities-section img {
  width: 100%;
}

.Home .Home-activities .Activities-section .Activity-no-image {
  width: 100%;
  height: 200px;
  background-color: #eeeeee;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Home .Home-activities .Activities-section .Activity-no-image h1 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 900;
  font-size: 24px;
  color: #aaaaaa;
}

.Home .Home-activities .Activities-section .Activity-no-image:hover h1 {
  text-decoration: none;
  color: #999999;
}

.Home .Home-testimonials .Testimonials-img {
  background: url(/static/media/homeless-matter.226002f1.png) top center no-repeat no-repeat;
  background-size: cover;
  height: 450px;
}

.Home .Home-testimonials .Custom-content {
  background-color: #2EA493;
  color: #ffffff;
  height: 450px;
  padding: 80px 50px 50px 50px;
  position: relative;
}

.Home .Home-testimonials .Custom-content h3 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  font-size: 2rem;
}

.Home .Home-testimonials .Custom-content .Custom-line {
  width: 70px;
  height: 2px;
  background-color: #ffffff;
  margin-top: 20px;
  margin-bottom: 30px;
}

.Home .Home-testimonials .Custom-content p {
  max-width: 550px;
}

.Home .Home-testimonials .Dots-img {
  position: absolute;
  top: 0;
  right: 0;
}

.Home .Home-publications {
  text-align: center;
}

.Home .Home-publications .Publications-text {
  width: 50%;
  margin: 0 auto 40px auto;
}

.Home .Home-publications .Publications-section {
  border-top: 3px solid #2EA493;
  box-shadow: 0 0 15px #dadada;
  width: 100%;
  text-align: left;
  padding: 30px;
  margin-bottom: 20px;
  height: calc(100% - 30px);
}

.Home .Home-publications .Publications-section .Publications-title {
  font-weight: bold;
  font-size: 20px;
  margin: 20px 0;
}

.Home .Home-team {
  text-align: center;
  position: relative;
  color: #ffffff;
  background-color: #000000;
}

.Home .Home-team .Title-subtitle {
  color: #ffffff;
}

.Home .Home-team .Team-img {
  background: url(/static/media/team_work.c199b4bd.jpg) #000000 top center no-repeat no-repeat;
  width: 100%;
  height: 400px;
  background-size: cover;
  opacity: 0.8;
}

.Home .Home-team .Team-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.Login {
    margin-top: -75px;
}

.Login a {
    text-decoration: none;
}

.Login h1 {
    font-family: 'Montserrat', sans-serif;
    font-weight: 900;
    font-size: 1.25rem;
    color: #212529;
    text-decoration: none;
    margin-top: 120px;
}

.Login h2 {
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    font-size: 1.75rem;
    color: #212529;
    text-decoration: none;
    margin-top: 120px;
}

.Login h1:hover {
    color: #444;
    text-display: none;
}

.Login .Login-sidebar {
    background: #2EA493;
    width: 508px;
    height: 100%;
    position: fixed;
    right: 0;
    top: 0;
}

.Login .Login-sidebar .Login-sidebar-image {
    background-image: url(/static/media/login-bg.85f6b755.svg);
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
}

.Login .Login-form {
    margin-top: 30%;
}

.Login .Login-form input {
    display: block;
    width: 400px;
    margin-top: 20px;
    padding: 10px;
    border: none;
}

.Login .Login-form input[type="text"],
.Login .Login-form input[type="password"]{
    border: 1px solid #CACACA;
}

.Login .Login-form input[type="text"].error,
.Login .Login-form input[type="password"].error {
    border: 1px solid #ef4747;
}

.Login .Login-form input[type="submit"] {
    background: #2EA493;
    color: #ffffff;
}

.Login .Login-form input[type="submit"]:hover {
    background: #2EA493;
}

@media (max-width: 991px) {
    .Login .Login-sidebar {
        display: none;
    }
}
.Publications .Publications-header {
  background-color: #2EA493;
  color: #ffffff;
  height: 200px;
}

.Publications .Header-container {
  height: 200px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Publications .Header-container h1 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
}

.Publications .Header-container a {
  color: #ffffff;
}

.Publications .Publications-section {
  min-height: calc(100vh - 620px);
}

.Publications .Publications-section .Section-group {
  margin: 40px 0;
}

.Publications .Publications-section .Section-group h2 {
  font-weight: bold;
  font-size: 24px;
}

.Publications .Publications-section .Section-group .Section-group-content {
  margin: 40px 0;
}
.Team .Team-header {
  background-color: #2EA493;
  color: #ffffff;
  height: 200px;
}

.Team .Header-container {
  height: 200px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Team .Header-container h1 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
}

.Team .Header-container a {
  color: #ffffff;
}

.Team .Team-section .Section-group {
  margin: 100px 0;
}

.Team .Team-section .Section-group h2 {
  font-weight: bold;
  font-size: 24px;
}

.Team .Team-section .Section-group .Section-group-content {
  text-align: center;
  min-height: 450px;
}

.Team .Team-section .Section-group .Section-group-content .Section-group-image {
  width: 100%;
  margin-top: 20px;
}

.Team .Team-section .Section-group .Section-group-content .Section-group-special-text {
  color: #999999;
}

.Team .Team-section .Section-group .Section-group-content .Section-group-name {
  font-weight: bold;
  margin-top: 10px;
  font-size: 24px;
}

.Team .Team-section .Section-group .Section-group-content .Section-group-title {
  font-weight: bold;
  color: #2EA493;
}

.Team .Team-section .Section-group .Section-group-content .Section-group-email {
  margin-left: 10px;
  color: #999999;
}
.App {
  margin-top: 75px;
}

/* TODO - remove this once ActivityEdit and PageNotFound are done */
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

