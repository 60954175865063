.Login {
    margin-top: -75px;
}

.Login a {
    text-decoration: none;
}

.Login h1 {
    font-family: 'Montserrat', sans-serif;
    font-weight: 900;
    font-size: 1.25rem;
    color: #212529;
    text-decoration: none;
    margin-top: 120px;
}

.Login h2 {
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    font-size: 1.75rem;
    color: #212529;
    text-decoration: none;
    margin-top: 120px;
}

.Login h1:hover {
    color: #444;
    text-display: none;
}

.Login .Login-sidebar {
    background: #2EA493;
    width: 508px;
    height: 100%;
    position: fixed;
    right: 0;
    top: 0;
}

.Login .Login-sidebar .Login-sidebar-image {
    background-image: url("../_images/login-bg.svg");
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
}

.Login .Login-form {
    margin-top: 30%;
}

.Login .Login-form input {
    display: block;
    width: 400px;
    margin-top: 20px;
    padding: 10px;
    border: none;
}

.Login .Login-form input[type="text"],
.Login .Login-form input[type="password"]{
    border: 1px solid #CACACA;
}

.Login .Login-form input[type="text"].error,
.Login .Login-form input[type="password"].error {
    border: 1px solid #ef4747;
}

.Login .Login-form input[type="submit"] {
    background: #2EA493;
    color: #ffffff;
}

.Login .Login-form input[type="submit"]:hover {
    background: #2EA493;
}

@media (max-width: 991px) {
    .Login .Login-sidebar {
        display: none;
    }
}