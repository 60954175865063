.PageNotFound {
    min-height: calc(100vh - 380px);
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.PageNotFound h1 {
    font-family: 'Montserrat';
    font-size: 100px;
}
