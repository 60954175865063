@import url('https://fonts.googleapis.com/css?family=Montserrat:500,600,900&display=swap');
@import url('https://fonts.googleapis.com/css?family=Roboto:400,700&display=swap');
@import url('https://db.onlinewebfonts.com/c/100dfc4e81c630ca07b6327a25d08f4a?family=Linux+Libertine');

body {
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
  font-weight: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #000000;
}

body *::selection {
  background-color: #2EA493;
  color: #ffffff;
}

body *::-moz-selection {
  background-color: #2EA493;
  color: #ffffff;
}

a {
  color: #2EA493;
}

a:hover,
a:active,
a:focus {
  color: #2EA493;
  text-decoration: underline;
  outline: none;
}

.Btn-primary {
  display: inline-block;
  padding: 4px 32px;
  margin: 0;
  font-size: 14px;
  font-weight: 600;
  line-height: 2.3;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  user-select: none;
  border: 1px solid transparent;
  height: 40px;
  color: #ffffff;
  background-color: #2EA493;
  text-shadow: none;
}

.Btn-primary:hover,
.Btn-primary:active,
.Btn-primary:focus {
  color: #ffffff;
  text-decoration: none;
  background-color: #2F9A89;
}

.Btn-secondary {
  display: inline-block;
  padding: 4px 32px;
  margin: 0;
  font-size: 14px;
  font-weight: bold;
  line-height: 2.3;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  user-select: none;
  border: 1px solid #333333;
  height: 40px;
  color: #333333;
  text-shadow: none;
  background-color: transparent;
}

.Btn-secondary:hover,
.Btn-secondary:active,
.Btn-secondary:focus {
  color: #ffffff;
  text-decoration: none;
  background-color: #333333;
}

.Switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 22px;
}

.Switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.Switch .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.Switch .slider:before {
  position: absolute;
  content: "";
  height: 14px;
  width: 14px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

.Switch input:checked + .slider {
  background-color: #2EA493;
}

.Switch input:focus + .slider {
  box-shadow: 0 0 1px #2EA493;
}

.Switch input:checked + .slider:before {
  -webkit-transform: translateX(18px);
  -ms-transform: translateX(18px);
  transform: translateX(18px);
}

/* Rounded sliders */
.Switch .slider {
  border-radius: 34px;
}

.Switch .slider:before {
  border-radius: 50%;
}