.ActivityNew .hidden {
    display: none !important;
}

.ActivityNew .ActivityNew-buttons {
    display: block;
    text-align: right;
    margin-top: 120px;
    margin-bottom: 40px;
}

.ActivityNew .ActivityNew-buttons button + button {
    margin-left: 10px;
}

.ActivityNew .Text-input {
    display: block;
    margin: 20px 0;
    min-width: 500px;
    width: 100%;
    padding: 10px;
    border: 1px solid #CACACA;
}

.ActivityNew .Dropdown-input .dropdown-toggle {
    background-color: #333333;
    border: 1px solid #333333;
    border-radius: 25px;
    box-shadow: none;
    outline: 0;
}

.ActivityNew .Dropdown-input .dropdown-toggle:hover,
.ActivityNew .Dropdown-input .dropdown-toggle:focus,
.ActivityNew .Dropdown-input .dropdown-toggle:active,
.ActivityNew .Dropdown-input .dropdown-toggle.active {
    outline: 0;
    background-color: #333333;
    border: 1px solid #333333;
    box-shadow: none !important;
}

.ActivityNew .Dropdown-input .dropdown-item:active {
    background-color: #eeeeee;
    color: #16181b;
}

.ActivityNew .ActivityNew-translations {
    padding: 15px 15px 5px 15px;
    background-color: #F5F5F5;
    margin: 15px 0;
}

.ActivityNew .Language-switch {
    display: inline-block;
    margin-left: 15px;
}

.ActivityNew .Language-switch .Switch {
    margin-right: 5px;
}

.ActivityNew .ActivityNew-Language-tabs {
    margin-top: 40px;
}

.ActivityNew .Label {
    border-radius: 25px;
    padding: 2px 10px;
    margin-right: 15px;
}

.ActivityNew .Label.active {
    background-color: #2EA493;
    border: 1px solid #2EA493;
    color: #ffffff;
}

.ActivityNew .Label:not(.disabled) {
    /*cursor: pointer;*/
}

.ActivityNew .Label.disabled {
    color: #999999;
}

.ActivityNew .Label.active.disabled {
    background-color: #F5F5F5;
    border-color: #F5F5F5;
}

.ActivityNew .ActivityNew-editor {
    margin: 50px 0;
}

.ActivityNew .Activity-wrapper a {
    color: #000000;
}

.ActivityNew .Activity-wrapper .rdw-editor-toolbar {
    border: 1px solid #CACACA;
    margin-bottom: 0;
    border-bottom: none;
}

.ActivityNew .Activity-editor {
    height: 500px;
    padding: 0 20px;
    border: 1px solid #CACACA;
}
