@media (min-width: 768px) {
  .Header .navbar {
    height: 75px;
  }
}

.Header .navbar-brand {
  font-family: 'Montserrat', sans-serif;
  font-weight: 900;
}

.Header .navbar-brand:hover {
  color: #444;
}

.Header .navbar-nav {
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  font-size: 12px;
}

.Header .navbar-nav .nav-link {
  color: black;
}

.Header .navbar-nav .nav-link:hover,
.Header .navbar-nav .nav-link:active,
.Header .navbar-nav .nav-link:focus,
.Header .navbar-nav .nav-link.active {
  color: #2EA493;
}

.Header .navbar-toggler:focus {
  outline: none;
}

.Header .Language-dropdown .dropdown-menu {
  min-width: 7rem;
}

.Header .Language-dropdown .dropdown-item {
  font-size: 14px;
}

.Header .Language-dropdown .dropdown-item.active,
.Header .Language-dropdown .dropdown-item:active {
  background-color: #2EA493;
}

@media (max-width: 767px) {
  .Header .navbar-collapse .nav-link {
    font-size: 16px;
  }
}