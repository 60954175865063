.Activities .row {
    margin-right: 0;
}

.Activities-popular {
    color: #ffffff;
    background-color: #2EA493;
    padding: 40px;
    height: 100%;
}

.Activities-popular h2 {
    font-size: 18px;
    text-align: center;
    margin: 30px 0;
}

.Activities-popular .Activities-recent-item {
    margin-top: 10px;
}

.Activities-popular .Activities-recent-item:hover {
    cursor: pointer;
}

.Activities-popular .Activities-recent-item img {
    width: 100%;
    height: 200px;
    object-fit: cover;
}

.Activities-popular .Activities-recent-item .Activity-no-image {
    width: 100%;
    height: 200px;
    background-color: #eeeeee;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}

.Activities-popular .Activities-recent-item .Activity-no-image h1 {
    font-family: 'Montserrat', sans-serif;
    font-weight: 900;
    font-size: 24px;
    color: #aaaaaa;
}

.Activities-popular .Activities-recent-item .Activity-no-image:hover h1 {
    text-decoration: none;
    color: #999999;
}

.Activities-popular .Activities-recent-item:hover h3 {
    text-decoration: underline;
}

.Activities-popular .Activities-recent-item h3 {
    font-size: 16px;
    margin: 15px 0;
}

.Activities .Activities-list {
    margin: 60px;
}

.Activities .Activities-list h2 {
    font-family: 'Montserrat';
    font-weight: 600;
}

.Activities .Activities-list .Activities-navigation {
    float: right;
    margin-top: -35px;
    font-size: 14px;
}

.Activities .Activities-list .Activities-list-item {
    border-bottom: 2px solid #E6E6E6;
    padding: 30px 0;
}

.Activities .Activities-list .Activities-list-item .Activity-no-image {
    width: 100%;
    height: 250px;
    background-color: #eeeeee;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}

.Activities .Activities-list .Activities-list-item .Activity-no-image h1 {
    font-family: 'Montserrat', sans-serif;
    font-weight: 900;
    color: #aaaaaa;
}

.Activities .Activities-list .Activities-list-item .Activity-no-image:hover h1 {
    text-decoration: none;
    color: #999999;
}

.Activities .Activities-list .Activities-list-item img {
    cursor: pointer;
    width: 100%;
    height: 250px;
    object-fit: cover;
}

.Activities .Activities-list .Activities-list-item .hr {
    width: 50px;
    height: 3px;
    background-color: #2EA493;
    margin-bottom: 15px;
}

.Activities .Activities-list .Activities-list-item h1 {
    font-size: 24px;
    margin-top: 10px;
    margin-bottom: 15px;
}

.Activities .Activities-list .Activities-list-item h1:hover {
    text-decoration: underline;
    cursor: pointer;
}

.Activities .Activities-list .Activities-list-item .Activities-list-details {
    color: #999999;
    margin-bottom: 15px;
}

.Activities .Activities-list .Activities-list-item .Activities-list-details .dot {
    display: inline-block;
    background-color: #2EA493;
    width: 5px;
    height: 5px;
    margin: 0 20px 3px 20px;
}

.Activities .Activities-list .Activities-list-item .Activities-list-details .Activities-list-type {
    background-color: #2EA493;
    color: #ffffff;
    padding: 5px 10px;
    border-radius: 20px;
}

.Activities .Activities-list .Activities-list-item .Activities-list-details .Btn-primary {
    margin-top: 20px;
}

.Activities .Activities-list .Activities-list-item .Activities-list-buttons > a + a,
.Activities .Activities-list .Activities-list-item .Activities-list-buttons > a + button {
    margin-left: 10px;
}

.Activities .Activities-pagination  {
    margin-top: 40px;
}

.Activities .Activities-pagination span {
    border: 1px solid #E6E6E6;
    color: #2EA493;
    padding: 10px 15px;
    cursor: pointer;
    font-weight: 600;
}

.Activities .Activities-pagination span.active {
    color: #ffffff;
    background-color: #2EA493;
    border: 1px solid #2EA493;
}

.Activities .Activities-pagination span.disabled {
    color: #999999;
}
