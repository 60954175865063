.About .About-header {
  background-color: #2EA493;
  color: #ffffff;
  height: 200px;
}

.About .Header-container {
  height: 200px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.About .Header-container h1 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
}

.About .Header-container a {
  color: #ffffff;
}

.About .About-project {
  text-align: center;
  width: 50%;
  margin: 0 auto;
}

.About .About-researchers {
  background-color: #f5f5f5;
  min-height: 450px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 60px;
}

.About .About-researchers .Researchers-images {
  display: flex;
  justify-content: space-around;
}

.About .About-researchers .Researchers-images div {
  display: flex;
  flex-direction: column;
  text-align: center;
}

.About .About-researchers .Researchers-images img {
  height: 300px;
}

@media (max-width: 991px) {
  .About .About-researchers .Researchers-images {
    margin-top: 30px;
  }

  .About .About-researchers .Researchers-text {
    margin-top: 30px;
    margin-bottom: 30px;
  }
}

.About .About-researchers .Researchers-text h3 {
  font-weight: bold;
  font-size: 20px;
  margin-bottom: 10px;
}

.About .About-researchers .Researchers-text p {
  font-size: 16px;
}

.About .About-purpose {
  min-height: 450px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.About .About-purpose .Purpose-text h3 {
  font-weight: bold;
  font-size: 20px;
  margin-bottom: 10px;
}

.About .About-purpose .Purpose-locations {
  height: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.About .About-purpose .Location-box {
  width: 120px;
  height: 170px;
  box-shadow: 0 0 15px #dadada;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.About .About-purpose .Location-box img {
  height: 40px;
  margin-top: 10px;
  margin-bottom: 15px;
}

.About .About-purpose .Location-box .Location-city {
  font-weight: bold;
  font-size: 20px;
}

.About .About-numbers {
  background-color: #2EA493;
  min-height: 200px;
  color: #ffffff;
}

.About .About-numbers .Numbers-text {
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
}

.About .About-numbers .Numbers-text .Numbers-number {
  font-weight: bold;
  font-size: 50px;
}

.About .About-supporters .Supporters-logos {
  margin-top: 80px;
  margin-bottom: 100px;
  text-align: center;
}

.About .About-collaborators {
  background-color: #f5f5f5;
  padding: 10px 50px 50px 50px;
}

.About .About-collaborators h3 {
  font-weight: bold;
  font-size: 20px;
}

.About .About-collaborators .Collaborators-links h3 {
  margin-top: 40px;
  font-size: 24px;
}

.About .About-collaborators .Collaborators-links div {
  margin-top: 20px;
  font-weight: bold;
}

.About .About-activities {
  text-align: center;
}

.About .About-activities h3 {
  margin-top: 80px;
  margin-bottom: 50px;
  font-weight: bold;
}

.About .About-activities .Activities-section {
  border-top: 3px solid #2EA493;
  box-shadow: 0 0 15px #dadada;
  width: 100%;
  text-align: left;
  padding: 30px;
  margin-bottom: 20px;
  height: calc(100% - 30px);
  cursor: pointer;
}

.About .About-activities .Activities-section .Activities-title {
  font-weight: bold;
  font-size: 20px;
  margin: 20px 0;
}

.About .About-activities .Activities-section:hover .Activities-title {
  text-decoration: underline;
}

.About .About-activities .Activities-section img {
  width: 100%;
}

.About .About-activities .Activities-section .Activity-no-image {
  width: 100%;
  height: 200px;
  background-color: #eeeeee;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.About .About-activities .Activities-section .Activity-no-image h1 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 900;
  font-size: 24px;
  color: #aaaaaa;
}

.About .About-activities .Activities-section .Activity-no-image:hover h1 {
  text-decoration: none;
  color: #999999;
}

.About .About-activities .Btn-primary {
  margin-bottom: 50px;
}