.Home .Home-hero {
  position: relative;
}

.Home .Home-hero .Home-background {
  background: url("../_images/bg-header.png") #000000 left center no-repeat no-repeat;
  background-size: cover;
  width: 100%;
  height: calc(100vh - 75px);
}

/* TODO - Home-background responsiveness */

.Home .Home-hero .Home-text {
  width: 100%;
  text-align: center;
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, 0);
  color: #ffffff;
}

.Home .Home-hero .Home-text h2 {
  font-size: 20px;
}

.Home .Home-hero .Home-text h1 em {
  color: #2EA493;
  font-family: 'Linux Libertine', serif;
  font-size: 50px;
}

.Home .Home-hero .Home-explore {
  cursor: pointer;
}

/* TODO - add a change on hover */
.Home .Home-hero .Home-explore .Home-arrows {
  position: absolute;
  bottom: 65px;
  left: 50%;
  transform: translate(-50%, 0);
}

.Home .Home-hero .Home-explore .Home-explore-btn {
  color: #ffffff;
  font-size: 14px;
  position: absolute;
  bottom: 15px;
  left: 50%;
  transform: translate(-50%, 0);
}

.Home .Home-about,
.Home .Home-goals,
.Home .Home-custom,
.Home .Home-activities,
.Home .Home-testimonials,
.Home .Home-publications,
.Home .Home-team {
  margin-top: 120px;
}


.Home .Home-about .Collaboration-img {
  max-width: 100%;
  max-height: 380px;
}

.Home .Home-about .About-emphasized {
  font-size: 20px;
}

.Home .Home-goals {
  margin-bottom: 40px;
}

.Home .Home-goals .Goals-section {
  box-shadow: 0 0 15px #dadada;
  width: 100%;
  text-align: center;
  padding: 30px;
  margin-bottom: 20px;
  height: calc(100% - 30px);
}

.Home .Home-goals .Goals-section img {
  height: 100px;
}

.Home .Home-goals .Goals-section h3 {
  font-size: 18px;
  font-weight: bold;
  margin-top: 20px;
  margin-bottom: 10px;
}

.Home .Home-goals .Goals-section p {
  font-size: 14px;
  color: #999999;
}

.Home .Home-custom .Woman-img {
  background: url("../_images/woman.png") bottom center no-repeat no-repeat;
  background-size: cover;
  height: 450px;
}

.Home .Home-custom .Custom-content {
  background-color: #000000;
  color: #ffffff;
  height: 450px;
  padding: 60px 80px;
  position: relative;
}

.Home .Home-custom .Custom-content h3 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  font-size: 2rem;
}

.Home .Home-custom .Custom-content .Custom-line {
  width: 70px;
  height: 2px;
  background-color: #2EA493;
  margin-top: 20px;
  margin-bottom: 30px;
}

.Home .Home-custom .Custom-content p {
  max-width: 460px;
  margin-bottom: 30px;
}

.Home .Home-custom .Custom-content .Dots-img {
  position: absolute;
  bottom: 0;
  right: 0;
}

.Home .Home-activities {
  text-align: center;
}

.Home .Home-activities .Activities-text {
  width: 50%;
  margin: 0 auto 30px auto;
}

.Home .Home-activities .Activities-section {
  border-top: 3px solid #2EA493;
  box-shadow: 0 0 15px #dadada;
  width: 100%;
  text-align: left;
  padding: 30px;
  margin-bottom: 20px;
  height: calc(100% - 30px);
  cursor: pointer;
}

.Home .Home-activities .Activities-section .Activities-title {
  font-weight: bold;
  font-size: 20px;
  margin: 20px 0;
}

.Home .Home-activities .Activities-section:hover .Activities-title {
  text-decoration: underline;
}

.Home .Home-activities .Activities-section img {
  width: 100%;
}

.Home .Home-activities .Activities-section .Activity-no-image {
  width: 100%;
  height: 200px;
  background-color: #eeeeee;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Home .Home-activities .Activities-section .Activity-no-image h1 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 900;
  font-size: 24px;
  color: #aaaaaa;
}

.Home .Home-activities .Activities-section .Activity-no-image:hover h1 {
  text-decoration: none;
  color: #999999;
}

.Home .Home-testimonials .Testimonials-img {
  background: url("../_images/homeless-matter.png") top center no-repeat no-repeat;
  background-size: cover;
  height: 450px;
}

.Home .Home-testimonials .Custom-content {
  background-color: #2EA493;
  color: #ffffff;
  height: 450px;
  padding: 80px 50px 50px 50px;
  position: relative;
}

.Home .Home-testimonials .Custom-content h3 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  font-size: 2rem;
}

.Home .Home-testimonials .Custom-content .Custom-line {
  width: 70px;
  height: 2px;
  background-color: #ffffff;
  margin-top: 20px;
  margin-bottom: 30px;
}

.Home .Home-testimonials .Custom-content p {
  max-width: 550px;
}

.Home .Home-testimonials .Dots-img {
  position: absolute;
  top: 0;
  right: 0;
}

.Home .Home-publications {
  text-align: center;
}

.Home .Home-publications .Publications-text {
  width: 50%;
  margin: 0 auto 40px auto;
}

.Home .Home-publications .Publications-section {
  border-top: 3px solid #2EA493;
  box-shadow: 0 0 15px #dadada;
  width: 100%;
  text-align: left;
  padding: 30px;
  margin-bottom: 20px;
  height: calc(100% - 30px);
}

.Home .Home-publications .Publications-section .Publications-title {
  font-weight: bold;
  font-size: 20px;
  margin: 20px 0;
}

.Home .Home-team {
  text-align: center;
  position: relative;
  color: #ffffff;
  background-color: #000000;
}

.Home .Home-team .Title-subtitle {
  color: #ffffff;
}

.Home .Home-team .Team-img {
  background: url("../_images/team_work.jpg") #000000 top center no-repeat no-repeat;
  width: 100%;
  height: 400px;
  background-size: cover;
  opacity: 0.8;
}

.Home .Home-team .Team-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}