.Title {
  margin-top: 60px;
  margin-bottom: 40px;
  text-align: center;
}

.Title .Title-main {
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
}

.Title .Title-subtitle {
  color: #999999;
}

.Title .Title-line {
  width: 70px;
  height: 3px;
  background-color: #2EA493;
  margin: 0 auto;
}