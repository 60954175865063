.Team .Team-header {
  background-color: #2EA493;
  color: #ffffff;
  height: 200px;
}

.Team .Header-container {
  height: 200px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Team .Header-container h1 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
}

.Team .Header-container a {
  color: #ffffff;
}

.Team .Team-section .Section-group {
  margin: 100px 0;
}

.Team .Team-section .Section-group h2 {
  font-weight: bold;
  font-size: 24px;
}

.Team .Team-section .Section-group .Section-group-content {
  text-align: center;
  min-height: 450px;
}

.Team .Team-section .Section-group .Section-group-content .Section-group-image {
  width: 100%;
  margin-top: 20px;
}

.Team .Team-section .Section-group .Section-group-content .Section-group-special-text {
  color: #999999;
}

.Team .Team-section .Section-group .Section-group-content .Section-group-name {
  font-weight: bold;
  margin-top: 10px;
  font-size: 24px;
}

.Team .Team-section .Section-group .Section-group-content .Section-group-title {
  font-weight: bold;
  color: #2EA493;
}

.Team .Team-section .Section-group .Section-group-content .Section-group-email {
  margin-left: 10px;
  color: #999999;
}